import React from "react";
import { Container, Navbar, Nav, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";

import logo from "../img/logo-white.png";

const AdminNav = () => {
  if (localStorage.getItem("token")) {
    return (
      <Dropdown as={Nav.Item} key="admin-menu" id="admin-dropdown">
        <Dropdown.Toggle as={Nav.Link}>ME</Dropdown.Toggle>
        <Dropdown.Menu alignRight className="bg-light text-right">
          <Nav.Item>
            <Nav.Link href="/admin">ADMIN</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="/"
              active={false}
              onSelect={(_, event) => {
                event.preventDefault();
                localStorage.removeItem("expires");
                localStorage.removeItem("token");
                window.location.reload();
              }}
            >
              LOGOUT
            </Nav.Link>
          </Nav.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return null;
  }
};

const NavLink = withRouter(({ href, children, history }) => {
  const currentPath = history.location.pathname.split("/")[1];
  const linkPath = href.split("/")[1];

  return (
    <Nav.Item>
      <Nav.Link href={href} active={currentPath === linkPath}>
        {children}
      </Nav.Link>
    </Nav.Item>
  );
});

const Header = () => (
  <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
    <Container fluid>
      <Navbar.Brand>
        <Link to="/">
          <img src={logo} height={71} alt="SoundWorks Collection" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav>
          <NavLink href="/videos">VIDEOS</NavLink>
          <NavLink href="/news">NEWS</NavLink>
          <NavLink href="/podcast">PODCAST</NavLink>
          <NavLink href="/about">ABOUT</NavLink>
          <NavLink href="/search">
            <FontAwesomeIcon icon={["fa", "search"]} />
          </NavLink>
          <AdminNav />
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default Header;
