import React from "react";
import { SocialIcon } from "react-social-icons";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import applePodcasts from "../img/apple-podcasts.png";

const iconStyle = { height: 32, width: 32, margin: 10 };

export default () => (
  <Row className="footer">
    <Col md={7}>
      <span className="copyright">
        Copyright &copy; {moment().format("YYYY")} SoundWorks Collection
      </span>
      <span>Colemanfilm Media Group LLC</span>

      <span className="fine-print">
        All names, trademarks and images are copyright of their respective
        owners.
      </span>
    </Col>
    <Col md={5} className="d-flex justify-content-end align-items-center">
      <SocialIcon
        style={iconStyle}
        url="https://www.facebook.com/SoundWorksCollection"
      />
      <SocialIcon
        style={iconStyle}
        url="https://instagram.com/soundworkscollection"
      />
      <SocialIcon
        style={iconStyle}
        url="https://youtube.com/@SoundWorksCollection"
      />
      <SocialIcon
        style={iconStyle}
        url="https://open.spotify.com/show/4Yvv5mWbvWI4MSv4sg88wA?si=nKiq6bvZT6-EwkZXBGRFkA"
      />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://itunes.apple.com/us/podcast/soundworks-collection/id685229051?mt=2"
      >
        <img src={applePodcasts} alt="Apple Podcasts" style={iconStyle} />
      </a>
    </Col>
  </Row>
);
